import service from 'dcom-app/dist/services';

const servicesApi = {
  list: params =>
    service()
      .get('/expense', { params })
      .then(response => response.data)
      .catch(error => error),
  create: payload =>
    service()
      .post('/expense', payload)
      .then(response => response.data)
      .catch(error => error),
  update: (id, payload) =>
    service()
      .patch(`/expense/${id}`, payload)
      .then(response => response.data)
      .catch(error => error),
  bulkUpdate: params => {
    const { ids, status, pid } = params;

    return service()
      .post('/expense/bulk_update', {
        ids: ids.join(),
        status,
        pid
      })
      .then(response => response.data)
      .catch(error => error);
  },
  bulkDelete: params => {
    return service()
      .delete('/expense/bulk_delete', {
        params: {
          ids: params.ids.join()
        }
      })
      .then(response => response.data)
      .catch(error => error);
  },
  bulkActualize: params => {
    return service().post(`/expense/bulk_actualize`, {
      ids: params.ids.join()
    });
  },
  uploadFile: (queryParameters, payload) => {
    const queryString = new URLSearchParams(queryParameters).toString();

    return service()
      .post(`/expense/upload?${new URLSearchParams(queryString).toString()}`, payload)
      .then(response => response.data)
      .catch(error => error);
  },
  getExpenseSummary: params => {
    return service()
      .get('/expense/get_expense_summary', {params})
      .then(response => response.data)
      .catch(error => error);
  },
  getJobStatus: params => {
    return service()
      .get('/job', { params })
      .then(response => response.data)
      .catch(error => error);
  }
};

export default servicesApi;
