const MODULE = 'REPORTS-CAMP';

const actions = {
    LOAD:`LOAD_${MODULE}`,
    LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
    LOAD_LEADS_REPORT: `LOAD_LEADS_REPORT_${MODULE}`,
    LOAD_LEADS_REPORT_SUCCESS: `LOAD_LEADS_REPORT_SUCCESS_${MODULE}`,

    // load
    load: (startDate, endDate, affiliateId) => {
        return {
            type: actions.LOAD,
            payload: {startDate, endDate, affiliateId}
        };
    },
    loadSuccess: data => ({
        type: actions.LOAD_SUCCESS,
        payload: { data },
    }),
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: { error },
    }),

    loadLeadsReport: data => ({
        type: actions.LOAD_LEADS_REPORT,
        payload: { ...data },
    }),
    loadLeadsReportSuccess: data => ({
        type: actions.LOAD_LEADS_REPORT_SUCCESS,
        payload: { data },
    })
};
export default actions;
