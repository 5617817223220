import service from "dcom-app/dist/services";

const servicesApi = {
    list: () =>
        service()
            .get('/creative')
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/creative', payload)
            .then(response => response.data)
            .catch(error => error),
    update: (id, payload) =>
        service()
            .patch(`/creative/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/creative/${id}`)
            .then(response => response.data)
            .catch(error => error),
    uploadFile: payload =>
        service()
            .post('/creative/upload', payload)
            .then(response => response.data)
            .catch(error => error),
    download: fileName =>
        service()
            .get(`/creative/getsignedurl/?filename=${fileName}`)
            .then(response => response.data)
            .catch(error => error),
    getAffAssets: (affID) =>
        service()
            .get(`/affiliate/${affID}/getallcreatives`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
