import service from 'dcom-app/dist/services';

const servicesApi = {
  list: (params) =>
    service()
      .get('/expensesMapping', { params })
      .then((response) => response.data)
      .catch((error) => error),
  create: (payload) =>
    service()
      .post('/expensesMapping', payload)
      .then((response) => response.data)
      .catch((error) => error),
  update: (id, payload) =>
    service()
      .patch(`/expensesMapping/${id}`, payload)
      .then((response) => response.data)
      .catch((error) => error),
  delete: (id) =>
    service()
      .delete(`/expensesMapping/${id}`)
      .then((response) => response.data)
      .catch((error) => error),
};

export default servicesApi;
