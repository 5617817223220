/* eslint-disable camelcase */
import FormV1Img from '../../../assets/images/widgets/form-v1.png';
import FormV2Img from '../../../assets/images/widgets/form-v2.png';
import FormV2CCDImg from '../../../assets/images/widgets/form-v2-ccd.png';
import PayoffV1Img from '../../../assets/images/widgets/payoff-v1.png';
import PayoffV2Img from '../../../assets/images/widgets/payoff-v2.png';
import ComparisonCalcImg from '../../../assets/images/widgets/comparison-calc.png';
import CallbackCalendarImg from '../../../assets/images/widgets/callbackcalendar-v1.png';

const widgetsInfoCopy = [
  [
    {
      id: 'dcomFormV1',
      img: FormV1Img,
      headline: 'Form V1',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
      bullets: ['Multilanguage ENG/SPA', 'Verticals configurables', 'Customized look & feel', 'Responsive']
    },
    {
      id: 'dcomFormV2',
      img: FormV2Img,
      headline: 'Form V2',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ',
      bullets: ['Multilanguage ENG/SPA', 'Verticals configurables', 'Customized look & feel', 'Responsive']
    }
  ],
  [
    {
      id: 'dcomFormV2CCD',
      img: FormV2CCDImg,
      headline: 'Form V2 CCD',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley',
      bullets: ['Multilanguage ENG/SPA', 'Verticals configurables', 'Customized look & feel', 'Responsive']
    },
    {
      id: 'dcomPayoffV1',
      img: PayoffV1Img,
      headline: 'PayOff Calculator V1',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      bullets: ['Multilanguage ENG/SPA', 'Verticals configurables', 'Customized look & feel', 'Responsive']
    }
  ],
  [
    {
      id: 'dcomPayoffV2',
      img: PayoffV2Img,
      headline: 'PayOff Calculator V2',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley',
      bullets: ['Multilanguage ENG/SPA', 'Verticals configurables', 'Customized look & feel', 'Responsive']
    },
    {
      id: 'dcomComparisonCalc',
      img: ComparisonCalcImg,
      headline: 'Comparison Calculator',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      bullets: ['Multilanguage ENG/SPA', 'Verticals configurables', 'Customized look & feel', 'Responsive']
    }
  ],
  [
    {
      id: 'callbackScheduler',
      img: CallbackCalendarImg,
      headline: 'Callback Scheduler',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley',
      bullets: ['Multilanguage ENG/SPA', 'Customized look & feel', 'Responsive']
    },
  ]
];

const widgetsFormConfiguration = {
  dcomFormV1: {
    name: 'Dcom Form v1',
    codePattern: 'dcomFormV1',
    withVerticals: true,
    parent_id: { defaultValue: 'dcomForm', configurableByUser: false, required: true },
    customize: [
      {
        type: 'switch',
        key: 'switch_oneColumn',
        label: 'One Column Widget Layout',
        name: 'oneColumn',
        defaultChecked: false,
        configurableByUser: true,
        required: false
      },
      {
        type: 'select',
        key: 'select_version',
        label: 'Widget Version',
        name: 'version',
        size: 'default',
        defaultValue: '1.0',
        hasFeedback: true,
        selectOptions: [
          { key: 'version_1', value: '1.0', text: '1.0' },
          { key: 'version_2', value: '2.0', text: '2.0' }
        ],
        configurableByUser: false,
        required: false
      },
      {
        type: 'select',
        key: 'select_language',
        defaultValue: 'EN',
        label: 'Widget Language',
        name: 'language',
        hasFeedback: true,
        size: 'default',
        selectOptions: [
          { key: 'lang_1', value: 'EN', text: 'English' },
          { key: 'lang_2', value: 'ES', text: 'Español' }
        ],
        configurableByUser: true,
        required: false
      },
      {
        type: 'select',
        key: 'select_styleGuide',
        label: 'Widget Style',
        name: 'styleGuide',
        hasFeedback: true,
        size: 'default',
        defaultValue: 'light',
        selectOptions: [
          { key: 'style_1', value: 'light', text: 'Light' },
          { key: 'style_2', value: 'custom', text: 'Custom' }
        ],
        configurableByUser: true,
        required: false
      },
      {
        type: 'verticals',
        headline: 'Activate Verticals',
        verticals: [
          {
            type: 'switch',
            key: 'switch_credit_card_debt',
            label: 'Credit card debt',
            name: 'credit_card_debt',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          },
          {
            type: 'switch',
            key: 'switch_student_loan_debt',
            label: 'Student loan debt',
            name: 'student_loan_debt',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          },
          {
            type: 'switch',
            key: 'switch_credit_correction',
            label: 'Credit Repair',
            name: 'credit_correction',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          },
          {
            type: 'switch',
            key: 'switch_tax_debt',
            label: 'Tax debt',
            name: 'tax_debt',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          }
        ]
      }
    ]
  },
  dcomFormV2: {
    name: 'Dcom Form v2',
    codePattern: 'dcomFormV2',
    withVerticals: true,
    parent_id: { defaultValue: 'dcomFormV2', configurableByUser: false, required: true },
    customize: [
      {
        type: 'select',
        key: 'select_version',
        label: 'Widget Version',
        name: 'version',
        size: 'default',
        defaultValue: '1.0',
        hasFeedback: true,
        selectOptions: [
          { key: 'version_1', value: '1.0', text: '1.0' },
          { key: 'version_2', value: '2.0', text: '2.0' }
        ],
        configurableByUser: false,
        required: false
      },
      {
        type: 'select',
        key: 'select_language',
        defaultValue: 'EN',
        label: 'Widget Language',
        name: 'language',
        hasFeedback: true,
        size: 'default',
        selectOptions: [
          { key: 'lang_1', value: 'EN', text: 'English' },
          { key: 'lang_2', value: 'ES', text: 'Español' }
        ],
        configurableByUser: true,
        required: false
      },
      {
        type: 'select',
        key: 'select_styleGuide',
        label: 'Widget Style',
        name: 'styleGuide',
        hasFeedback: true,
        size: 'default',
        defaultValue: 'light',
        selectOptions: [
          { key: 'style_1', value: 'light', text: 'Light' },
          { key: 'style_2', value: 'custom', text: 'Custom' }
        ],
        configurableByUser: true,
        required: false
      },
      {
        type: 'verticals',
        headline: 'Activate Verticals',
        verticals: [
          {
            type: 'switch',
            key: 'switch_credit_card_debt',
            label: 'Credit card debt',
            name: 'credit_card_debt',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          },
          {
            type: 'switch',
            key: 'switch_student_loan_debt',
            label: 'Student loan debt',
            name: 'student_loan_debt',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          },
          {
            type: 'switch',
            key: 'switch_credit_correction',
            label: 'Credit Repair',
            name: 'credit_correction',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          },
          {
            type: 'switch',
            key: 'switch_tax_debt',
            label: 'Tax debt',
            name: 'tax_debt',
            defaultChecked: true,
            configurableByUser: true,
            required: false
          }
        ]
      }
    ]
  },
  dcomFormV2CCD: {
    name: 'Dcom Form CCD',
    codePattern: 'dcomFormV2CCD',
    withVerticals: false,
    parent_id: { defaultValue: 'dcomFormV2', configurableByUser: false, required: true },
    customize: [
      {
        type: 'select',
        key: 'select_version',
        label: 'Widget Version',
        name: 'version',
        size: 'default',
        defaultValue: '1.0',
        hasFeedback: true,
        selectOptions: [
          { key: 'version_1', value: '1.0', text: '1.0' },
          { key: 'version_2', value: '2.0', text: '2.0' }
        ],
        configurableByUser: false,
        required: false
      },
      {
        type: 'select',
        key: 'select_language',
        defaultValue: 'EN',
        label: 'Widget Language',
        name: 'language',
        hasFeedback: true,
        size: 'default',
        selectOptions: [
          { key: 'lang_1', value: 'EN', text: 'English' },
          { key: 'lang_2', value: 'ES', text: 'Español' }
        ],
        configurableByUser: true,
        required: false
      },

      {
        type: 'select',
        key: 'select_styleGuide',
        label: 'Widget Style',
        name: 'styleGuide',
        hasFeedback: true,
        size: 'default',
        defaultValue: 'light',
        selectOptions: [
          { key: 'style_1', value: 'light', text: 'Light' },
          { key: 'style_2', value: 'custom', text: 'Custom' }
        ],
        configurableByUser: true,
        required: false
      }
    ]
  },
  dcomPayoffV1: {
    name: 'Dcom Payoff Calculator v1',
    codePattern: 'dcomPayoffV1',
    withVerticals: false,
    parent_id: { defaultValue: 'dcomPayOffV1', configurableByUser: false, required: true },
    customize: [
      {
        type: 'select',
        key: 'select_styleGuide',
        label: 'Widget Style',
        name: 'styleGuide',
        hasFeedback: true,
        size: 'default',
        defaultValue: 'light',
        selectOptions: [
          { key: 'style_1', value: 'light', text: 'Light' },
          { key: 'style_2', value: 'custom', text: 'Custom' }
        ],
        configurableByUser: true,
        required: false
      }
    ]
  },
  dcomPayoffV2: {
    name: 'Dcom Payoff Calculator v2',
    codePattern: 'dcomPayoffV2',
    widget: { defaultValue: 'dcomPayOffV2', configurableByUser: false, required: true },
    parent_id: { defaultValue: 'dcomPayOffV2', configurableByUser: false, required: true },
    customize: [
      {
        type: 'select',
        key: 'select_styleGuide',
        label: 'Widget Style',
        name: 'styleGuide',
        hasFeedback: true,
        size: 'default',
        defaultValue: 'light',
        selectOptions: [
          { key: 'style_1', value: 'light', text: 'Light' },
          { key: 'style_2', value: 'custom', text: 'Custom' }
        ],
        configurableByUser: true,
        required: false
      }
    ]
  },
  dcomComparisonCalc: {
    name: 'Dcom Comparison Calculator',
    codePattern: 'dcomComparisonCalc',
    parent_id: { defaultValue: 'dcomComparison', configurableByUser: false, required: true },
    customize: [
      {
        type: 'switch',
        key: 'switch_fullVersion',
        label: 'Widget full version',
        name: 'fullVersion',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'select',
        key: 'switch_styleGuide',
        label: 'Widget Style',
        name: 'styleGuide',
        hasFeedback: true,
        size: 'default',
        defaultValue: 'light',
        selectOptions: [
          { key: 'style_1', value: 'light', text: 'Light' },
          { key: 'style_2', value: 'custom', text: 'Custom' }
        ],
        configurableByUser: true,
        required: false
      }
    ]
  },
  callbackScheduler: {
    name: 'Callback Scheduler Form',
    codePattern: 'callbackScheduler',
    withVerticals: false,
    parent_id: { defaultValue: 'callbackScheduler', configurableByUser: false, required: true },
    customize: [
      {
        type: 'text',
        key: 'drops_campaign_id_key',
        defaultValue: '',
        label: 'Campaign Id',
        name: 'drops_campaign_id',
        hasFeedback: true,
        configurableByUser: true,
        required: true
      },
      {
        type: 'hidden',
        key: 'select_affIdTitle',
        defaultValue: 'Debt.com',
        name: 'affIdTitle',
        configurableByUser: false,
        required: true
      },
      {
        type: 'select',
        key: 'select_language',
        defaultValue: 'EN',
        label: 'Widget Language',
        name: 'language',
        hasFeedback: true,
        size: 'default',
        selectOptions: [
          { key: 'lang_1', value: 'EN', text: 'English' },
          { key: 'lang_2', value: 'ES', text: 'Español' }
        ],
        configurableByUser: true,
        required: false
      },
      {
        type: 'text',
        key: 'calendar_time_lap',
        defaultValue: '15',
        label: 'Time Lapse Space',
        name: 'timeLaps',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'text',
        key: 'calendar_number_days',
        defaultValue: '20',
        label: 'Days to Move Forward',
        name: 'numberOfDays',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'calendar',
        key: 'calendar_calendar_input',
        label: 'Days Off (Blackout Days)',
        name: 'daysOff',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_sun',
        label: 'Sunday',
        name: 'sun',
        defaultChecked: false,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_mon',
        label: 'Monday',
        name: 'mon',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_tue',
        label: 'Tuesday',
        name: 'tue',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_wed',
        label: 'Wednesday',
        name: 'wed',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_thu',
        label: 'Thursday',
        name: 'thu',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_fri',
        label: 'Friday',
        name: 'fri',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'multiple',
        key: 'multiple_sat',
        label: 'Saturday',
        name: 'sat',
        defaultChecked: false,
        configurableByUser: true,
        required: false
      },
      {
        type: 'switch',
        key: 'switch_twocolumn',
        label: 'Two Column Form',
        name: 'twocolumn',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'switch',
        key: 'switch_bordered',
        label: 'Border Fields Design',
        name: 'bordered',
        defaultChecked: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'form_background_color',
        defaultValue: '#f6f6f6',
        label: 'Widget Background Color',
        name: 'formBackgroundColor',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'select_font_color',
        defaultValue: '#013c73',
        label: 'Widget Font Color',
        name: 'fontColor',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'calendar_color_on',
        defaultValue: '#287dad',
        label: 'Calendar Active Days Color',
        name: 'calendarColorON',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'calendar_color_off',
        defaultValue: '#939393',
        label: 'Calendar Days Color Off',
        name: 'calendarColorOFF',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'calendar_color_active',
        defaultValue: '#E8730F',
        label: 'Active Day Color',
        name: 'calendarColorACTIVE',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'calendar_color_hover',
        defaultValue: '#246f87',
        label: 'Color When Hover a Day',
        name: 'calendarColorHOVER',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'calendar_color_lines',
        defaultValue: '#ffffff',
        label: 'Calendar lines Color',
        name: 'calendarColorLines',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'form_fields_color',
        defaultValue: '#26748b',
        label: 'Widget Fields Border Color',
        name: 'fieldsBorderColor',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'btn_font_color',
        defaultValue: '#ffffff',
        label: 'CTA Font Color',
        name: 'btnCTAFontColor',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'btn_normal_color',
        defaultValue: '#527E3E',
        label: 'CTA Button Color',
        name: 'btnCTANormalColor',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },
      {
        type: 'colorpicker',
        key: 'btn_hover_color',
        defaultValue: '#6c9b57',
        label: 'CTA Hover Color',
        name: 'btnCTAHoveredColor',
        hasFeedback: true,
        configurableByUser: true,
        required: false
      },

    ]
  }
};

const widgetsDefaultValues = {
  dcomFormV1: {
    widget: 'dcomForm',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      version: '1.0',
      oneColumn: false,
      language: 'EN',
      activeVerticals: {
        credit_card_debt: true,
        student_loan_debt: true,
        credit_correction: true,
        tax_debt: true
      },
      styleGuide: 'light'
    }
  },
  dcomFormV2: {
    widget: 'dcomFormV2',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      version: '1.0',
      language: 'EN',
      activeVerticals: {
        credit_card_debt: true,
        student_loan_debt: true,
        credit_correction: true,
        tax_debt: true
      },
      styleGuide: 'light'
    }
  },
  dcomFormV2CCD: {
    widget: 'dcomFormV2',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      version: '1.0',
      language: 'EN',
      activeVerticals: {
        credit_card_debt: true,
        student_loan_debt: true,
        credit_correction: true,
        tax_debt: true
      },
      styleGuide: 'light',
      embedded: 'ccd'
    }
  },
  dcomPayoffV1: {
    widget: 'dcomPayOff',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      styleGuide: 'light'
    }
  },
  dcomPayoffV2: {
    widget: 'dcomPayOffV2',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      styleGuide: 'light'
    }
  },
  dcomComparisonCalc: {
    widget: 'dcomComparison',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      fullVersion: true,
      styleGuide: 'light'
    }
  },
  callbackScheduler: {
    widget: 'callbackScheduler',
    parent_id: 'dcom-widget-wrapper',
    customize: {
      affIdTitle: '',
      language: 'EN',
      drops_campaign_id: '',
      timeLaps: 15,
      numberOfDays: 20,
      daysOff: [
        '01-01-2020',
        '02-17-2020',
        '05-25-2020',
        '06-03-2020',
        '06-03-2020',
        '09-07-2020',
        '10-12-2020',
        '11-26-2020',
        '12-25-2020',
      ],
      sun: null,
      mon: ['9:00-17:00'],
      tue: ['8:00-16:00'],
      wed: ['8:00-12:00', '13:00-16:00'],
      thu: ['9:00-17:00'],
      fri: ['8:00-11:30', '13:00-17:30'],
      sat: null,
      twocolumn: true,
      bordered: true,
      formBackgroundColor: '#f6f6f6',
      fontColor: '#013c73',
      calendarColorON: '#287dad',
      calendarColorOFF: '#939393',
      calendarColorHOVER: '#246F87',
      calendarColorACTIVE: '#E8730F',
      calendarColorLines: '#ffffff',
      btnCTANormalColor: '#527E3E',
      btnCTAHoveredColor: '#6C9B57',
      btnCTAFontColor: '#ffffff',
      fieldsBorderColor: '#CeD6DD',
    }
  }
};

const widgetSnippetPatterns = {
  dcomFormV1: ({ parent_id, widget, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0oneColumn: "${customize.oneColumn}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0version: "${customize.version}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0language: "${customize.language}",\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0\xa0activeVerticals: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0credit_card_debt: "${customize.activeVerticals.credit_card_debt}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0student_loan_debt: "${customize.activeVerticals.student_loan_debt}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0credit_correction: "${customize.activeVerticals.credit_correction}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0tax_debt: "${customize.activeVerticals.tax_debt}"\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0\xa0},\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0styleGuide: "${customize.styleGuide}",\n` +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n',
  dcomFormV2: ({ widget, parent_id, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0language: "${customize.language}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0version: "${customize.version}",\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0\xa0activeVerticals: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0credit_card_debt: "${customize.activeVerticals.credit_card_debt}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0student_loan_debt: "${customize.activeVerticals.student_loan_debt}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0credit_correction:"${customize.activeVerticals.credit_correction}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0tax_debt: "${customize.activeVerticals.tax_debt}"\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0\xa0},\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0styleGuide: "${customize.styleGuide}",\n` +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n',
  dcomFormV2CCD: ({ widget, parent_id, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0language: "${customize.language}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0version: "${customize.version}",\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0\xa0activeVerticals: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0credit_card_debt: "${customize.activeVerticals.credit_card_debt}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0student_loan_debt: "${customize.activeVerticals.student_loan_debt}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0credit_correction:"${customize.activeVerticals.credit_correction}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0tax_debt: "${customize.activeVerticals.tax_debt}"\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0\xa0},\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0styleGuide: "${customize.styleGuide}",\n` +
    '\xa0\xa0\xa0\xa0\xa0\xa0embedded: "ccd"\n' +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n',
  dcomPayoffV1: ({ widget, parent_id, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0styleGuide: "${customize.styleGuide}",\n` +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n',
  dcomPayoffV2: ({ widget, parent_id, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0styleGuide: "${customize.styleGuide}",\n` +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n',
  dcomComparisonCalc: ({ widget, parent_id, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0fullVersion: "${customize.fullVersion}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0styleGuide: "${customize.styleGuide}",\n` +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n',
  callbackScheduler: ({ widget, parent_id, customize }) =>
    '\xa0\xa0window.dcomWidgets = {\n' +
    '\xa0\xa0\xa0\xa0info: [\n' +
    '\xa0\xa0\xa0\xa0{\n' +
    `\xa0\xa0\xa0\xa0\xa0widget: "${widget}",\n` +
    `\xa0\xa0\xa0\xa0\xa0parent_id: "${parent_id}",\n` +
    '\xa0\xa0\xa0\xa0\xa0customize: {\n' +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0affIdTitle: "${customize.affIdTitle}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0language: "${customize.language}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0drops_campaign_id: "${customize.drops_campaign_id}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0timeLaps: "${customize.timeLaps}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0numberOfDays: "${customize.numberOfDays}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0daysOff: "${customize.daysOff}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0sun: "${customize.sun}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0mon: "${customize.mon}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0tue: "${customize.tue}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0wed: "${customize.wed}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0thu: "${customize.thu}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0fri: "${customize.fri}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0sat: "${customize.sat}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0twocolumn: "${customize.twocolumn}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0bordered: "${customize.bordered}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0formBackgroundColor: "${customize.formBackgroundColor}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0fontColor: "${customize.fontColor}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0calendarColorON: "${customize.calendarColorON}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0calendarColorOFF: "${customize.calendarColorOFF}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0calendarColorHOVER: "${customize.calendarColorHOVER}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0calendarColorACTIVE: "${customize.calendarColorACTIVE}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0calendarColorLines: "${customize.calendarColorLines}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0btnCTANormalColor: "${customize.btnCTANormalColor}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0btnCTAHoveredColor: "${customize.btnCTAHoveredColor}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0btnCTAFontColor: "${customize.btnCTAFontColor}",\n` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0fieldsBorderColor: "${customize.fieldsBorderColor}",\n` +
    '\xa0\xa0\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0}\n' +
    '\xa0\xa0\xa0]\n' +
    '\xa0\xa0}\n'
};

const codeToUser = ({ parent_id }, code) =>
  '<div>\n' +
  '\xa0<script>\n' +
  `${code}` +
  '\xa0</script>\n' +
  `\xa0<div id="${parent_id}"></div>\n` +
  '\xa0<script src="https://ewc.debt.com/cdn/main/dwm.js"></script>\n' +
  '</div>\n';

export { widgetsInfoCopy, widgetsFormConfiguration, widgetsDefaultValues, widgetSnippetPatterns, codeToUser };
