import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  expensesMapping: [],
  selected: null,
  expensesCSV: [],
  alertVisible: false,
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.LOAD_EXPENSES_MAPPING:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_EXPENSES_MAPPING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expensesMapping: payload.data,
        errorMessage: false,
      };

    case actions.LOAD_EXPENSES_MAPPING_ERROR:
      return {
        ...state,
        modalActive: state.modalActive,
        isLoading: false,
        errorMessage: payload && payload.error,
      };

    case actions.LOAD_EXPENSES_MAPPING_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expensesMapping: [...state.expensesMapping, ...payload.data],
        errorMessage: false,
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selected: payload.data || null,
      };

    case actions.UPDATE_SELECTED:
      return {
        ...state,
        selected: payload.data,
      };
    case actions.LOAD_EXPENSES_MAPPING_CSV_SUCCESS:
      return {
        ...state,
        expensesCSV: payload,
        alertVisible: payload.length === 0 ? true : false,
      };
    case actions.CLEAN_LOAD_EXPENSES_MAPPING_CSV_SUCCESS:
      return {
        ...state,
        expensesCSV: [],
      };
    case actions.RESET_ALERT:
      return {
        ...state,
        alertVisible: false,
      };
    default:
      return state;
  }
}
