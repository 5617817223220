const MODULE = 'EXPENSES_MAPPING';

const actions = {
  LOAD_EXPENSES_MAPPING_SUCCESS: `LOAD_${MODULE}_SUCCESS`,
  LOAD_EXPENSES_MAPPING: `LOAD_${MODULE}`,
  LOAD_EXPENSES_MAPPING_NEXT_PAGE_SUCCESS: `LOAD_${MODULE}_NEXT_PAGE_SUCCESS`,
  LOAD_EXPENSES_MAPPING_ERROR: `LOAD_${MODULE}_ERROR`,
  TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
  SAVE_EXPENSE_MAPPING: `SAVE_EXPENSE_MAPPING_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  LOAD_EXPENSES_MAPPING_CSV: `LOAD_EXPENSES_MAPPING_CSV_${MODULE}`,
  LOAD_EXPENSES_MAPPING_CSV_SUCCESS: `LOAD_EXPENSES_MAPPING_CSV_SUCCESS_${MODULE}`,
  CLEAN_LOAD_EXPENSES_MAPPING_CSV_SUCCESS: `CLEAN_LOAD_EXPENSES_MAPPING_CSV_SUCCESS_${MODULE}`,
  RESET_ALERT: `RESET_ALERT_${MODULE}`,

  // load
  loadExpensesMapping: (searchParams) => {
    return { type: actions.LOAD_EXPENSES_MAPPING, ...searchParams };
  },
  loadExpensesMappingNextPage: (tablePagination) => {
    return { type: actions.LOAD_EXPENSES_MAPPING, tablePagination };
  },
  loadExpensesMappingSuccess: (data) => ({
    type: actions.LOAD_EXPENSES_MAPPING_SUCCESS,
    payload: { data },
  }),
  loadExpensesMappingNextPageSuccess: (data) => ({
    type: actions.LOAD_EXPENSES_MAPPING_NEXT_PAGE_SUCCESS,
    payload: { data },
  }),
  loadExpensesMappingError: (error) => ({
    type: actions.LOAD_EXPENSES_MAPPING_ERROR,
    payload: { error },
  }),

  // edit
  saveExpenseMapping: (data, actionName = 'insert', callBack = false) => ({
    type: actions.SAVE_EXPENSE_MAPPING,
    payload: { data, actionName, callBack },
  }),

  // modal
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL,
    payload: { data },
  }),

  // selected
  updateSelected: (data) => ({
    type: actions.UPDATE_SELECTED,
    payload: { data },
  }),
  listExpensesMappingCSV: (data) => {
    return {
      type: actions.LOAD_EXPENSES_MAPPING_CSV,
      payload: data,
    };
  },
  listExpensesMappingCSVSuccess: (data) => {
    return {
      type: actions.LOAD_EXPENSES_MAPPING_CSV_SUCCESS,
      payload: data,
    };
  },
  cleanListExpensesMappingCSV: () => {
    return {
      type: actions.CLEAN_LOAD_EXPENSES_MAPPING_CSV_SUCCESS,
    };
  },
  resetAlert: () => {
    return {
      type: actions.RESET_ALERT,
    };
  },
};
export default actions;
