import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from "antd";
import servicesApi from "./services";
import actions from './actions';

function* list(action) {
    try {
        const {startDate, endDate, affiliateId} = action.payload;
        const result = yield call(servicesApi.list, startDate, endDate, affiliateId);

        // success
        if (!result.isAxiosError && result.response.campaign_summary_response.success) {
            const reports = result.response.campaign_summary_response.campaigns.campaign_summary;
            yield put(actions.loadSuccess(reports));
            return;
        }

        // error
        const {message} = result;
        console.log("Error: list report:-->", message);
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadError(error));
    }
}

function* loadLeadsReport(action) {
    try {

        const {startDate, endDate, affiliateId, site_offer_id} = action.payload;
        const result = yield call(servicesApi.leadsReport, startDate, endDate, affiliateId, site_offer_id);

        // success
        if (!result.isAxiosError && result.response.LeadsByAffiliateExportResponses.success) {
            let reports = [];
            if(result.response.LeadsByAffiliateExportResponses.row_count > 0){
                 reports = result.response.LeadsByAffiliateExportResponses.leads_by_affiliate.LeadsByAffiliate;
            }
            yield put(actions.loadLeadsReportSuccess(reports));
            return;
        }

        // error
        const {message} = result;
        console.log("Error: loadLeadsReport:-->", message);
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, list),
        takeEvery(actions.LOAD_LEADS_REPORT, loadLeadsReport),
    ]);
}
