import asyncComponent from 'dcom-app/dist/helpers/AsyncFunc';

const modulesRoutes = [
  {
    path: '',
    component: asyncComponent(() => import('./containers/dashboard')),
    roles:[1, 2]
  },
  {
    path: 'affiliates',
    component: asyncComponent(() => import('./containers/affiliates')),
    roles: [1]
  },
  {
    path: 'expenses',
    component: asyncComponent(() => import('./containers/expenses')),
    roles: [1,3]
  },
  {
    path: 'expenses-mapping',
    component: asyncComponent(() => import('./containers/expenses-mapping')),
    roles: [1,3]
  },
  {
    path: 'expenses-ops',
    component: asyncComponent(() => import('./containers/expenses-ops')),
    roles: [1,3]
  },// TODO adding route should be automatically
  {
    path: 'audit',
    component: asyncComponent(() => import('./containers/audit')),
    roles: [1,3]
  },
  {
    path: 'widget-builder',
    component: asyncComponent(() => import('./containers/widget-builder')),
    exact: false,
    roles: [1, 2]
  },
  {
    path: 'assets',
    component: asyncComponent(() => import('./containers/assets')),
    roles: [1, 2]
  },
  {
    path: 'campaign-summary',
    component: asyncComponent(() => import('./containers/reportingCamp')),
    roles: [1, 2]
  },
  {
    path: 'conversions',
    component: asyncComponent(() => import('./containers/reportingConv')),
    roles: [1, 2]
  },
  {
    path: 'user',
    component: asyncComponent(() => import('./containers/user')),
    roles: [1],
  },
  {
    path: 'role',
    component: asyncComponent(() => import('./containers/role')),
    roles: [1],
  },
  {
    path: 'permission',
    component: asyncComponent(() => import('./containers/permission')),
    roles: [1],
  },
  {
    path: 'permission-ui',
    component: asyncComponent(() => import('./containers/permissionUI')),
    roles: [1],
  },
];

export const getPublicRouters = (roles) => {
  return modulesRoutes.filter(v => v.roles.length === 0);
};

export const getPrivateRouters = (roles) => {
  return  modulesRoutes.filter( r => r.roles.length > 0 && r.roles.some( value => roles.includes(value)));
};

export const getAppRouters = (roles, isLoggedIn, permissions= []) => {

  if (!permissions || permissions.length === 0) return [];

  const result = [];

  permissions.forEach((x) => {

    const p = x.route || '';

    if (!result.find((x)=>x.path === p)){
      const m = modulesRoutes.find((x)=>x.path === p);
      if (m){
        result.push(m)
      }
    }

  })

  // const publicRouters = getPublicRouters(roles);
  // const privateRouters = getPrivateRouters(roles);
  //
  // if (!isLoggedIn) return [...publicRouters];
  //
  // return [...publicRouters, ...privateRouters];

  return result;
  // return modulesRoutes;
};


// TODO: remove this code
// export const getAppRouters = (roles, isLoggedIn) => {
//   const publicRouters = getPublicRouters(roles);
//   const privateRouters = getPrivateRouters(roles);
//
//   if(!isLoggedIn)
//     return [...publicRouters];
//
//   return [...publicRouters, ...privateRouters];
// };

