import service from "dcom-app/dist/services";

const servicesApi = {
    list: (params) =>
        service()
            .get('/user', {params})
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/user', payload)
            .then(response => response.data)
            .catch(error => error),
    update: (id, payload) =>
        service()
            .patch(`/user/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/user/${id}`)
            .then(response => response.data)
            .catch(error => error),
    createUser: payload =>
        service()
            .post('/signup', payload)
            .then(response => response.data)
            .catch(error => error),

    loginAsAffiliate: userId =>
        service()
            .post('/signin_as_affiliate', {userId})
            .then(response => response.data)
            .catch(error => error),
    count: (params) =>
      service()
        .get('/count/user', {params})
        .then(response => response.data)
        .catch(error => error),
};

export default servicesApi;
