import service from "dcom-app/dist/services";

const servicesApi = {
    loadReport: (startDate, endDate, affiliateId, campaignId) =>
        service()
            .get(`/report/events_conversions?startDate=${startDate}&endDate=${endDate}&affiliateId=${affiliateId}&campaignId=${campaignId}`)
            .then(response => response.data)
            .catch(error => error),

    loadCampaigns: (affiliateId) =>
        service() //
            .get(`/report/get_campaigns?affiliateId=${affiliateId}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
