import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import servicesApi from './services';
import actions from './actions';

function* listAffiliates({data: filters}) {
  try {

    const [count, result] = yield all([
      call(servicesApi.count, filters),
      call(servicesApi.list, filters)
    ]);

    // success
    if (!result.isAxiosError) {
      yield put(actions.loadAffiliateSuccess(result, count.count));
      return;
    }

    // error
    const { message } = result;
    console.log('Error: listUsers:-->', message);
    yield put(actions.loadAffiliatesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadAffiliatesError(error));
  }
}

function* saveAffiliate({ payload }) {
  const { data, actionName } = payload;

  const { id, firstName, lastName, email, password, affPhone, affCompany } = data;

  let result = null;
  try {
    switch (actionName) {
      case 'update': {

        const user = {
          companies: affCompany,
          email,
          firstName,
          lastName,
          roles: affPhone,
          username: email,
        };

        if (password) {
          user.password = password;
        }

        result = yield call(servicesApi.update, id, user);
        setTimeout(() => MessageManager.success('User Updated'), 500);
        break;
      }
      case 'delete':
        result = yield call(servicesApi.delete, id);
        setTimeout(() => MessageManager.success('User Deleted'), 500);
        break;

      default: {
        // insert
        const user = {
          companies: affCompany,
          email,
          firstName,
          lastName,
          roles: affPhone,
          username: email,
          password
        };
        result = yield call(servicesApi.create, user);
        setTimeout(() => MessageManager.success('User Created'), 500);
        break;
      }
    }

    // success
    if (!result.isAxiosError) {
      yield put({ type: actions.LOAD_AFFILIATES });
      return;
    }

    // error
    const { message } = result;
    console.log('Error: saveUser:-->', message);
    yield put(actions.loadAffiliatesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadAffiliatesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_AFFILIATES, listAffiliates),
    takeEvery(actions.SAVE_AFFILIATE, saveAffiliate)
  ]);
}
