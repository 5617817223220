const MODULE = 'ASSETS';

const actions = {
    LIST_ASSETS:`LIST_ASSETS_${MODULE}`,
    LOAD_ASSET_SUCCESS: `LOAD_ASSET_SUCCESS_${MODULE}`,
    LOAD_ASSETS:`LOAD_ASSETS_${MODULE}`,
    LOAD_ASSETS_ERROR: `LOAD_ASSET_ERROR_${MODULE}`,
    TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
    SAVE_ASSET: `SAVE_ASSET_${MODULE}`,
    UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,


    // load
    loadAssets: (affId) => {
        return {
            type: actions.LOAD_ASSETS,
            payload: {affId}
        };
    },
    loadAssetsSuccess: data => ({
        type: actions.LOAD_ASSET_SUCCESS,
        payload: { data },
    }),
    loadAssetsError: error => ({
        type: actions.LOAD_ASSETS_ERROR,
        payload: { error },
    }),

    // edit
    saveAsset: (data, actionName = 'insert') => ({
        type: actions.SAVE_ASSET,
        payload: { data, actionName },
    }),


    // modal
    toggleModal: (data = null) => ({
        type: actions.TOGGLE_HANDLE_MODAL,
        payload: { data },
    }),


    // selected
    updateSelected: data => ({
        type: actions.UPDATE_SELECTED,
        payload: { data}
    }),

};
export default actions;
