import actions from "./actions";

const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    affiliates: [],
    selected: null,
    total: 0
};

export default function reducer(state = initState, action) {
    const {type, payload} = action;
    switch (type) {

        case actions.LOAD_AFFILIATES:
            return {
                ...state,
                isLoading: true,
                errorMessage: false,
                modalActive: false,
            };

        case actions.LOAD_AFFILIATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                affiliates: payload.data,
                errorMessage: false,
                total: payload.count
            };

        case actions.LOAD_FROM_CAMPAIGN_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'There is a loading problem',
            };

        case actions.TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                selected: payload.data || null,
            };

        case actions.UPDATE_SELECTED:
            return {
               ...state,
               selected: payload.data
            };

        default:
            return state;
    }
}

