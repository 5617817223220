import service from "dcom-app/dist/services";

const servicesApi = {
    leadsReport: (startDate, endDate, affiliateId, site_offer_id) =>
        service()
            .get(`/report/leads_by_affiliate?startDate=${startDate}&endDate=${endDate}&affiliateId=${affiliateId}&site_offer_id=${site_offer_id}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
