import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { message as MessageManager } from "antd";
import servicesApi from "./services";
import actions from './actions';
import {getCurrentListSize} from "./reducers";

/**
 *
 * @param tablePagination
 * @param searchQuery
 * @param sorter
 * @returns {Generator<*, void, *>}
 */
function* listAudit({pagination, searchQuery, sorter}) {
    try {

        let pageSettings = {};
        const listSize = yield select(getCurrentListSize);

        if (pagination && pagination.current > 1 && listSize%30 === 0) {
            pageSettings['skip'] = pagination.current * pagination.pageSize;
        }

        // add filters parameters
        if (searchQuery) {
            pageSettings = {
                ...pageSettings,
                where: JSON.stringify(searchQuery)
            }
        }

        if (sorter && sorter.columnKey && sorter.order) {
            const orderMap = {
                'descend' : 'desc',
                'ascend' : 'asc'
            }
            pageSettings['sort'] = `${sorter.columnKey} ${orderMap[sorter.order]}`
        }

        const result = yield call(servicesApi.list, pageSettings);
        // success
        if (!result.isAxiosError) {
            if (pageSettings.skip > 0) {
                yield put(actions.loadAuditNextPageSuccess(result));
            }
            else {
                yield put(actions.loadAuditSuccess(result));
            }
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadAuditError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadAuditError(error));
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_AUDIT, listAudit),
    ]);
}

