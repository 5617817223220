const actions = {
  // uglify code
  UGLIFY_CODE: 'UGLIFY_CODE',
  UGLIFY_CODE_SUCCESS: 'UGLIFY_CODE_SUCCESS',
  UGLIFY_CODE_ERROR: 'UGLIFY_CODE_ERROR',
  // add widget
  ADD_WIDGET: 'ADD_WIDGET',
  ADD_WIDGET_SUCCESS: 'ADD_WIDGET_SUCCESS',
  ADD_WIDGET_ERROR: 'ADD_WIDGET_ERROR',
  // update widget
  UPDATE_WIDGET: 'UPDATE_WIDGET',
  UPDATE_WIDGET_SUCCESS: 'UPDATE_WIDGET_SUCCESS',
  UPDATE_WIDGET_ERROR: 'UPDATE_WIDGET_ERROR',
  // load list CRUD
  LOAD_LIST_WIDGETS: 'LOAD_LIST_WIDGETS',
  LOAD_LIST_WIDGETS_SUCCESS: 'LOAD_LIST_WIDGETS_SUCCESS',
  LOAD_LIST_WIDGETS_ERROR: 'LOAD_LIST_WIDGETS_ERROR',
  // delete widget in CRUD
  DELETE_WIDGET_FROM_LIST: 'DELETE_WIDGET_FROM_LIST',
  DELETE_WIDGET_FROM_LIST_SUCCESS: 'DELETE_WIDGET_FROM_LIST_SUCCESS',
  DELETE_WIDGET_FROM_LIST_ERROR: 'DELETE_WIDGET_FROM_LIST_ERROR',
  // load widget in the display
  LOAD_SHOWCASE_WIDGET: 'LOAD_SHOWCASE_WIDGET',
  // change widget config
  CHANGE_CURRENT_WIDGET_NAME: 'CHANGE_CURRENT_WIDGET_NAME',
  CHANGE_CURRENT_AFF_ID: 'CHANGE_CURRENT_AFF_ID',
  CHANGE_WIDGET_PROPERTIES: 'CHANGE_WIDGET_PROPERTIES',
  CHANGE_WIDGET_VERTICALS: 'CHANGE_WIDGET_VERTICALS',
  // generate widget code
  CREATE_WIDGET_CODE: 'CREATE_WIDGET_CODE',
  RESET_STORE: 'RESET_STORE',
  DISABLE_CONFIG_PANEL_CONTROLS: 'DISABLE_CONFIG_PANEL_CONTROLS',
  //  ACTIONS DISPATCHERS
  // uglify code snippet
  uglifyCode: data => ({ type: actions.UGLIFY_CODE, payload: data }),
  uglifyCodeSuccess: data => ({
    type: actions.UGLIFY_CODE_SUCCESS,
    payload: data
  }),
  uglifyCodeError: error => ({
    type: actions.UGLIFY_CODE_ERROR,
    payload: { error }
  }),
  // add widget
  addWidget: data => {
    return { type: actions.ADD_WIDGET, payload: data };
  },
  addWidgetSuccess: data => ({
    type: actions.ADD_WIDGET_SUCCESS,
    payload: data
  }),
  addWidgetError: error => ({
    type: actions.ADD_WIDGET_ERROR,
    payload: { error }
  }),
  // update widget
  updateWidget: data => {
    return { type: actions.UPDATE_WIDGET, payload: data };
  },
  updateWidgetSuccess: data => ({
    type: actions.UPDATE_WIDGET_SUCCESS,
    payload: data
  }),
  updateWidgetError: error => ({
    type: actions.UPDATE_WIDGET_ERROR,
    payload: { error }
  }),
  // load
  loadListWidgets: () => {
    return { type: actions.LOAD_LIST_WIDGETS };
  },
  loadListWidgetsSuccess: data => ({
    type: actions.LOAD_LIST_WIDGETS_SUCCESS,
    payload: { data }
  }),
  loadListWidgetsError: error => ({
    type: actions.LOAD_LIST_WIDGETS_ERROR,
    payload: { error }
  }),
  // delete widget from list
  deleteWidgetFromList: id => ({
    type: actions.DELETE_WIDGET_FROM_LIST,
    payload: id
  }),
  deleteWidgetFromListSuccess: data => ({
    type: actions.DELETE_WIDGET_FROM_LIST_SUCCESS,
    payload: data.id
  }),
  deleteWidgetFromListError: error => ({
    type: actions.DELETE_WIDGET_FROM_LIST_ERROR,
    payload: { error }
  }),
  // load widget on the display
  loadShowcaseWidget: data => ({
    type: actions.LOAD_SHOWCASE_WIDGET,
    payload: data
  }),
  // update widget properties
  changeCurrentWidgetName: name => ({ type: actions.CHANGE_CURRENT_WIDGET_NAME, payload: name }),
  changeCurrentAffId: id => ({ type: actions.CHANGE_CURRENT_AFF_ID, payload: id }),
  changeWidgetProperties: property => ({ type: actions.CHANGE_WIDGET_PROPERTIES, payload: property }),
  changeWidgetVerticals: vertical => ({ type: actions.CHANGE_WIDGET_VERTICALS, payload: vertical }),
  // generate code snippet
  createWidgetCode: code => ({
    type: actions.CREATE_WIDGET_CODE,
    payload: code
  }),
  // reset store
  resetStore: () => ({
    type: actions.RESET_STORE
  }),
  // disable config panle controls
  disableConfigControls: (status) => ({
    type:actions.DISABLE_CONFIG_PANEL_CONTROLS,
    payload: status
  })
};
export default actions;
