const MODULE = 'EXPENSES';

const actions = {
  LIST_EXPENSES: `LIST_EXPENSES_${MODULE}`,
  LOAD_EXPENSES_SUCCESS: `LOAD_EXPENSES_SUCCESS_${MODULE}`,
  LOAD_EXPENSES: `LOAD_EXPENSES_${MODULE}`,
  LOAD_EXPENSES_NEXT_PAGE_SUCCESS: `LOAD_EXPENSES_NEXT_PAGE_SUCCESS_${MODULE}`,
  LOAD_EXPENSES_SUMMARY_SUCCESS: `LOAD_EXPENSES_SUMMARY_SUCCESS_${MODULE}`,
  LOAD_EXPENSES_ERROR: `LOAD_EXPENSES_ERROR_${MODULE}`,
  TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
  SAVE_EXPENSES: `SAVE_EXPENSES_${MODULE}`,
  UPDATE_EXPENSES: `UPDATE_EXPENSES_${MODULE}`,
  DELETE_EXPENSES: `DELETE_EXPENSES_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  ACTUALIZE_EXPENSES: `ACTUALIZE_EXPENSES_${MODULE}`,
  GET_UPLOAD_REPORT_STATUS: `GET_UPLOAD_REPORT_STATUS_${MODULE}`,
  LOAD_EXPENSES_CSV: `LOAD_EXPENSES_CSV_${MODULE}`,
  LOAD_EXPENSES_CSV_SUCCESS: `LOAD_EXPENSES_CSV_SUCCESS_${MODULE}`,
  CLEAN_LOAD_EXPENSES_CSV_SUCCESS: `CLEAN_LOAD_EXPENSES_CSV_SUCCESS_${MODULE}`,
  RESET_ALERT: `RESET_ALERT_${MODULE}`,

  // load
  loadExpenses: ({ pagination = {}, searchQuery = {}, sorter = {} }, callback = () => {}) => {
    return {
      type: actions.LOAD_EXPENSES,
      pagination,
      searchQuery,
      sorter,
      callback,
    };
  },
  loadExpensesSuccess: (data) => ({
    type: actions.LOAD_EXPENSES_SUCCESS,
    payload: { data },
  }),
  loadExpensesNextPageSuccess: (data) => ({
    type: actions.LOAD_EXPENSES_NEXT_PAGE_SUCCESS,
    payload: { data },
  }),
  loadExpenseSummarySuccess: (data) => ({
    type: actions.LOAD_EXPENSES_SUMMARY_SUCCESS,
    payload: data,
  }),
  loadExpensesError: (error) => ({
    type: actions.LOAD_EXPENSES_ERROR,
    payload: { error },
  }),

  // edit
  saveExpense: (data, actionName = 'insert', callBack) => ({
    type: actions.SAVE_EXPENSES,
    payload: { data, actionName, callBack },
  }),

  // bulk edit
  updateExpenses: (data) => {
    return {
      type: actions.UPDATE_EXPENSES,
      payload: data,
    };
  },
  deleteExpenses: (data) => {
    return {
      type: actions.DELETE_EXPENSES,
      payload: data,
    };
  },
  actualizeExpenses: (data) => {
    return {
      type: actions.ACTUALIZE_EXPENSES,
      payload: data,
    };
  },

  // modal
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL,
    payload: { data },
  }),

  // selected
  updateSelected: (data) => ({
    type: actions.UPDATE_SELECTED,
    payload: { data },
  }),

  getUploadReportStatus: (data) => ({
    type: actions.GET_UPLOAD_REPORT_STATUS,
    payload: data,
  }),
  listExpensesCSV: (data) => {
    return {
      type: actions.LOAD_EXPENSES_CSV,
      payload: data,
    };
  },
  listExpensesCSVSuccess: (data) => {
    return {
      type: actions.LOAD_EXPENSES_CSV_SUCCESS,
      payload: data,
    };
  },
  cleanListExpensesCSV: () => {
    return {
      type: actions.CLEAN_LOAD_EXPENSES_CSV_SUCCESS,
    };
  },
  resetAlert: () => {
    return {
      type: actions.RESET_ALERT,
    };
  },
};
export default actions;
