import { message as MessageManager } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import servicesApi from './services';

/**
 *
 * @param tablePagination
 * @param searchQuery
 * @param sorter
 * @returns {Generator<*, void, *>}
 */
function* listExpensesMapping({ tablePagination, searchQuery, sorter }) {
  try {
    let pageSettings = {
      skip: (tablePagination && tablePagination.current * tablePagination.pageSize) || 0,
    };

    // add filters parameters
    if (searchQuery) {
      pageSettings = {
        ...pageSettings,
        where: JSON.stringify(searchQuery),
      };
    }

    const result = yield call(servicesApi.list, pageSettings);
    // success
    if (!result.isAxiosError) {
      if (pageSettings.skip > 0) {
        yield put(actions.loadExpensesMappingNextPageSuccess(result));
      } else {
        yield put(actions.loadExpensesMappingSuccess(result));
      }
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadExpensesMappingError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadExpensesMappingError(error));
  }
}

/**
 *
 * @param payload
 * @returns {Generator<*, *, null>}
 */
function* saveExpenseMapping({ payload }) {
  const { data, actionName, callBack = false } = payload;

  let result = null;
  try {
    switch (actionName) {
      case 'insert':
        const { campaignName = '', pid = 0, source = '' } = data;
        const existingRecord = yield call(servicesApi.list, { campaignName, pid, source });
        if (existingRecord && existingRecord.length > 0) {
          setTimeout(() => MessageManager.error('Mapping Item with defined attributes already exists'), 0);
          throw new Error('Mapping Item already exists');
        }

        result = yield call(servicesApi.create, data);
        setTimeout(() => MessageManager.success('ExpenseMapping Updated'), 500);
        break;

      case 'update':
        result = yield call(servicesApi.update, data.id, data);
        setTimeout(() => MessageManager.success('ExpenseMapping Updated'), 500);
        break;

      case 'delete':
        result = yield call(servicesApi.delete, data.id);
        setTimeout(() => MessageManager.success('ExpenseMapping Deleted'), 500);
        break;

      default:
        console.log('default action');
        break;
    }

    // success
    if (result && !result.isAxiosError && callBack) {
      return callBack();
    }

    // error
    const { message } = result;
    yield put(actions.loadExpensesMappingError({ error: message }));

    if (result && result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadExpensesMappingError(error));
  }
}

function* listExpensesMappingCSV({ payload }) {
  try {
    let pageSettings = {};
    pageSettings['skip'] = 0;

    pageSettings = {
      ...pageSettings,
      where: JSON.stringify(payload),
    };

    const result = yield call(servicesApi.list, pageSettings);

    if (!result.isAxiosError) {
      yield put(actions.listExpensesMappingCSVSuccess(result));
      return;
    }

    const { message } = result;
    yield put(actions.loadExpensesMappingError({ error: message }));
  } catch (error) {
    console.log(error);
    yield put(actions.loadExpensesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_EXPENSES_MAPPING, listExpensesMapping),
    takeEvery(actions.SAVE_EXPENSE_MAPPING, saveExpenseMapping),
    takeEvery(actions.LOAD_EXPENSES_MAPPING_CSV, listExpensesMappingCSV),
  ]);
}
