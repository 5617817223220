import { message as MessageManager } from 'antd';
import _first from 'lodash/first';
import _size from 'lodash/size';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import expensesMappingServicesApi from '../expenses-mapping/services';
import actions from './actions';
import { getCurrentListSize } from './reducers';
import servicesApi from './services';

function* listExpenses({ pagination, searchQuery, sorter, callback = () => {} }) {
  try {
    let pageSettings = {};
    const listSize = yield select(getCurrentListSize);

    if (pagination?.current > 1 && listSize % 30 === 0) {
      pageSettings['skip'] = pagination.current * pagination.pageSize;
    }

    // add filters parameters
    if (searchQuery) {
      pageSettings = {
        ...pageSettings,
        where: JSON.stringify(searchQuery),
      };
    }

    if (sorter && sorter.columnKey && sorter.order) {
      const orderMap = {
        descend: 'desc',
        ascend: 'asc',
      };
      pageSettings['sort'] = `${sorter.columnKey} ${orderMap[sorter.order]}`;
    }

    const [result = []] = yield all([
      call(servicesApi.list, pageSettings),
    ]);

    // success
    if (!result.isAxiosError) {
      if (pageSettings.skip > 0) {
        yield put(actions.loadExpensesNextPageSuccess(result));
      } else {
        yield put(actions.loadExpensesSuccess(result));
      }

      if (callback) {
        callback();
      }

      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadExpensesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadExpensesError(error));
  }
}

function* listExpensesCSV({ payload }) {
  try {
    let pageSettings = {};

    let result = [];
    let temporalResponse = null;
    let skip = 0;

    while (temporalResponse === null || temporalResponse.length > 0) {
      //pageSettings['limit'] = 60;
      if (skip > 0) {
        pageSettings['skip'] = skip;
      }

      pageSettings = {
        ...pageSettings,
        where: JSON.stringify(payload),
      };

      temporalResponse = yield call(servicesApi.list, pageSettings);

      result = result.concat(temporalResponse);
      skip = skip + 30;
    }

    if (!result.isAxiosError) {
      yield put(actions.listExpensesCSVSuccess(result));
      return;
    }

    const { message } = result;
    yield put(actions.loadExpensesMappingError({ error: message }));
  } catch (error) {
    console.log(error);
    yield put(actions.loadExpensesError(error));
  }
}

/**
 * Save Expenses
 * @param payload
 * @returns {Generator<*, void, null>}
 */
function* saveExpenses({ payload }) {
  const { data, actionName, callBack } = payload;

  let result = null;
  try {
    switch (actionName) {
      case 'insert':
        const { fileList } = data;
        if (!fileList) {
          return MessageManager.error('Error, no file selected');
        }

        result = yield call(servicesApi.uploadFile, data, fileList);
        if (result && result.jobId) {
          yield put(actions.getUploadReportStatus({ jobId: result.jobId }));
        }

        MessageManager.success('File Uploaded Successfully');
        break;

      case 'update':
        result = yield call(servicesApi.update, data.id, data);
        MessageManager.success(`Expense updated with PID (${result.pid})`);

        // Create new Expense Mapping from the record that has been updated
        if (result.id && result.pid) {
          const { campaignName, source, pid } = result;
          yield all([
            call(createExpensesMappingFromPID, { campaignName, source, pid }),
            call(updateExpensesFromMatchingCriteria, { campaignName, source, pid }),
          ]);
        }

        break;

      case 'delete':
        result = yield call(servicesApi.bulkDelete({ ids: [data.id] }));
        MessageManager.success('Expense Deleted');
        break;

      default:
        break;
    }

    // success
    if (!result?.isAxiosError && callBack) {
      return callBack();
    }

    // error
    if (result) {
      const { message } = result;
      console.log('Error: listAssets:-->', message);
      yield put(actions.loadExpensesError({ error: message }));

      if (result.response && result.response.status === 401) {
        MessageManager.error('Session expired please login again');
      }
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadExpensesError(error));
  }
}

/**
 *   Bulk Delete Expenses
 * @returns {Generator<*, void, *>}
 */
function* bulkDeleteExpenses({ payload }) {
  const { ids, callBack = false } = payload;
  let result = null;

  try {
    result = yield call(servicesApi.bulkDelete, { ids });
  } catch (error) {
    console.error(error);
    yield put(actions.loadExpensesError(error));
  }

  if (callBack) {
    callBack();
  }

  // success
  if (!result?.isAxiosError) {
    return result;
  }

  // error
  if (result) {
    const { message } = result;
    console.log('Error: listAssets:-->', message);
    yield put(actions.loadExpensesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  }
}

/**
 * Bu;lk Update Expenses
 * @param payload
 * @returns {Generator<*, void, null>}
 */
function* bulkUpdateExpenses({ payload }) {
  const { ids, callBack = false, status } = payload;
  let result = null;

  try {
    result = yield call(servicesApi.bulkUpdate, { ids, status });
  } catch (error) {
    console.error(error);
    yield put(actions.loadExpensesError(error));
  }

  if (callBack) {
    callBack();
  }

  // success
  if (!result?.isAxiosError) {
    return result;
  }

  // error
  if (result) {
    const { message } = result;
    console.log('Error: listAssets:-->', message);
    yield put(actions.loadExpensesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  }
}

function* bulkActualizeExpenses({ payload }) {
  const { ids, callBack = () => false } = payload;
  let result = null;

  try {
    result = yield call(servicesApi.bulkActualize, { ids });
  } catch (error) {
    console.error(error);
    yield put(actions.loadExpensesError(error));
  }

  if (callBack) {
    callBack();
  }

  // success
  if (!result?.isAxiosError) {
    return result;
  }

  // error
  if (result) {
    const { message } = result;
    console.log('Error: listAssets:-->', message);
    yield put(actions.loadExpensesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  }
}

/**
 * Check for expensesMapping and create one with matching criteria PID, source and campaignName
 * @param campaignName
 * @param source
 * @param pid
 * @returns {Generator<*, void, *>}
 */
function* createExpensesMappingFromPID({ campaignName, source, pid }) {
  const expensesMapping = yield call(expensesMappingServicesApi.list, {
    campaignName,
    source,
  });

  if (_size(expensesMapping) === 0) {
    const newExpenseMapping = yield call(expensesMappingServicesApi.create, {
      campaignName,
      source,
      status: 'active',
      pid,
    });

    if (newExpenseMapping.id) {
      MessageManager.success('New Expense Mapping Created');
    }
  }
}

/**
 * Update expenses with the missing PID that match criteria of the last updated item
 * @param campaignName
 * @param source
 * @param pid
 * @returns {Generator<*, void, *>}
 */
function* updateExpensesFromMatchingCriteria({ campaignName, source, pid }) {
  const expensesMissingPID = yield call(servicesApi.list, {
    campaignName,
    source,
    pid: '',
  });

  const ids = expensesMissingPID.map((expense) => {
    return expense.id;
  });

  if (_size(ids) === 0) {
    return;
  }

  const result = yield call(servicesApi.bulkUpdate, { ids, pid });
  if (result) {
    MessageManager.success(`${_size(ids)} New Expense Updated`);
  }
}

function* getUploadReportStatus({ payload }) {
  const { jobId = 0 } = payload;
  const result = yield call(servicesApi.getJobStatus, { where: { id: jobId } });

  if (result) {
    const jobObject = _first(result) || {};

    if (jobObject && jobObject.id) {
      switch (jobObject.status) {
        case 'processed':
          setTimeout(() => MessageManager.success('File processed!'), 100);
          break;
        case 'error':
          setTimeout(() => MessageManager.error('Failed to process data from the uploaded file'), 100);
          break;
        case 'new':
          if (jobId) {
            yield put(actions.getUploadReportStatus({ jobId: result.jobId }));
          }
          break;
        default:
          setTimeout(() => MessageManager.error('Failed to process data from the uploaded file'), 100);
          break;
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_EXPENSES, listExpenses),
    takeEvery(actions.SAVE_EXPENSES, saveExpenses),
    takeEvery(actions.UPDATE_EXPENSES, bulkUpdateExpenses),
    takeEvery(actions.DELETE_EXPENSES, bulkDeleteExpenses),
    takeEvery(actions.ACTUALIZE_EXPENSES, bulkActualizeExpenses),
    takeEvery(actions.GET_UPLOAD_REPORT_STATUS, getUploadReportStatus),
    takeEvery(actions.LOAD_EXPENSES_CSV, listExpensesCSV),
  ]);
}
