import affiliateSagas from '../containers/affiliates/sagas';
import expensesSagas from '../containers/expenses/sagas';
import expensesMappingSagas from '../containers/expenses-mapping/sagas';
import expensesOperationsSagas from '../containers/expenses-ops/sagas';
import widgetsSagas from '../containers/widget-builder/sagas';
import assetsSagas from '../containers/assets/sagas';
import reportSagas from '../containers/reportingCamp/sagas';
import dashBoardSagas from '../containers/dashboard/sagas';
import reportByAffiliateSagas from '../containers/reportingAff/sagas';
import reportConversionsSagas from '../containers/reportingConv/sagas';
import auditSagas from '../containers/audit/sagas';
import roleSagas from '../containers/role/sagas';
import permissionSagas from '../containers/permission/services';
import permissionUISagas from '../containers/permissionUI/services';
import userSagas from '../containers/user/sagas';

export default [
    affiliateSagas(),
    expensesSagas(),
    expensesMappingSagas(),
    expensesOperationsSagas(),
    widgetsSagas(),
    assetsSagas(),
    reportSagas(),
    dashBoardSagas(),
    reportByAffiliateSagas(),
    reportConversionsSagas(),
    auditSagas(),
    permissionSagas(),
    permissionUISagas(),
    roleSagas(),
    userSagas()
];
