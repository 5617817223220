const MODULE = 'EXPENSES_OPERATIONS';

const actions = {
  LOAD_EXPENSES_OPERATIONS_SUCCESS: `LOAD_${MODULE}_SUCCESS`,
  LOAD_EXPENSES_OPERATIONS_JOBS_SUCCESS: `LOAD_${MODULE}_JOBS_SUCCESS`,
  LOAD_EXPENSES_OPERATIONS: `LOAD_${MODULE}`,
  LOAD_EXPENSES_OPERATIONS_ERROR: `LOAD_${MODULE}_ERROR`,
  TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
  TOGGLE_RUN_MODAL: `TOGGLE_RUN_MODAL_${MODULE}`,
  SAVE_EXPENSE_OPERATION: `SAVE_EXPENSE_OPERATION_${MODULE}`,
  RUN_EXPENSE_OPERATION: `RUN_EXPENSE_OPERATION_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  START_BACKGROUND_SYNC: 'START_BACKGROUND_SYNC',
  STOP_BACKGROUND_SYNC: 'STOP_BACKGROUND_SYNC',

  loadExpensesOperations: (searchParams) => {
    return { type: actions.LOAD_EXPENSES_OPERATIONS, ...searchParams };
  },
  loadExpensesOperationsSuccess: data => ({
    type: actions.LOAD_EXPENSES_OPERATIONS_SUCCESS,
    payload: { data },
  }),
  loadExpensesOperationsJobsSuccess: data => ({
    type: actions.LOAD_EXPENSES_OPERATIONS_JOBS_SUCCESS,
    payload: { data },
  }),
  loadExpensesOperationsError: error => ({
    type: actions.LOAD_EXPENSES_OPERATIONS_ERROR,
    payload: { error },
  }),
  startJobsSync: payload => ({
    type: actions.START_BACKGROUND_SYNC,
    payload: payload,
  }),
  stopJobsSync: payload => ({
    type: actions.STOP_BACKGROUND_SYNC,
    payload
  }),

  // edit
  saveExpenseOperation: (data, actionName = 'insert', callBack = false) => ({
    type: actions.SAVE_EXPENSE_OPERATION,
    payload: { data, actionName, callBack },
  }),

  runExpenseOperation: (data, callBack = false) => ({
    type: actions.RUN_EXPENSE_OPERATION,
    payload: { data, callBack },
  }),

  // modal
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL,
    payload: { data },
  }),

  toggleRunModal: (data = null) => ({
    type: actions.TOGGLE_RUN_MODAL,
    payload: { data },
  }),
}

export default actions;
