const MODULE = 'REPORTS-AFF';

const actions = {
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
    LOAD_LEADS_REPORT: `LOAD_LEADS_REPORT_${MODULE}`,
    LOAD_LEADS_REPORT_SUCCESS: `LOAD_LEADS_REPORT_SUCCESS_${MODULE}`,

    // load
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: { error },
    }),

    loadLeadsReport: data => ({
        type: actions.LOAD_LEADS_REPORT,
        payload: { ...data },
    }),
    loadLeadsReportSuccess: data => ({
        type: actions.LOAD_LEADS_REPORT_SUCCESS,
        payload: { data },
    })
};
export default actions;
