// process.env.REACT_APP_ENV;
const ENV = window.location.host === 'partners-portal.debt.com' ? 'production' : 'development';

console.log('stated like:', ENV);

const BASE_URL =
  ENV === 'production' ? 'https://partners-portal-bk.debt.com' : 'https://dev-partners-portal-bk.debt.com';

const BASE_URL_DROPS_API = '';
// (ENV === 'production') ? 'https://drops-api.debt.com' : 'https://dev-drops-api.debt.com';

export { BASE_URL, BASE_URL_DROPS_API, ENV };
