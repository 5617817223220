import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  operationSettingsModalActive: false,
  expensesOperations: [],
  expensesOperationsJobs: [],
  selected: null
};


export default function reducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {

    case actions.LOAD_EXPENSES_OPERATIONS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_EXPENSES_OPERATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expensesOperations: payload.data,
        errorMessage: false,
      };

    case actions.LOAD_EXPENSES_OPERATIONS_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expensesOperationsJobs: payload.data,
        errorMessage: false,
      }

    case actions.LOAD_EXPENSES_OPERATIONS_ERROR:
      return {
        ...state,
        modalActive: state.modalActive,
        isLoading: false,
        errorMessage: payload && payload.error
      }

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selected: payload.data || null,
      };

    case actions.TOGGLE_RUN_MODAL:
      return {
        ...state,
        operationSettingsModalActive: !state.operationSettingsModalActive,
        selected: payload.data || null,
      };

    default:
      return state;
  }
}
