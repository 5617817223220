import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import servicesApi from './services';
import actions from './actions';

import history from 'dcom-app/dist/helpers/history';

function* listAffiliates() {
  try {
    const result = yield call(servicesApi.list);

    // success
    if (!result.isAxiosError) {
      yield put(actions.loadAffiliateSuccess(result));
      return;
    }

    // error
    const { message } = result;
    console.log('Error: listAffiliates:-->', message);
    yield put(actions.loadAffiliatesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadAffiliatesError(error));
  }
}

function* saveAffiliate({ payload }) {
  const { data, actionName } = payload;

  const { firstName, lastName, email, password, affName, affId, affPhone, affCompany } = data;

  let result = null;
  try {
    switch (actionName) {
      case 'update':
        result = yield call(servicesApi.update, data.id, data);
        setTimeout(() => MessageManager.success('Affiliate Updated'), 500);
        break;

      case 'delete':
        result = yield call(servicesApi.delete, data.id);
        setTimeout(() => MessageManager.success('Affiliate Deleted'), 500);
        break;

      default: // insert
        const u = { firstName, lastName, email, password, roles: [2], username: email };
        const d = yield call(servicesApi.createUser, u);

        if (d.data && d.data.user) {
          const aff = { user: d.data.user.id, Name: affName, AffId: affId, Phone: affPhone, company: affCompany };
          result = yield call(servicesApi.create, aff);
          setTimeout(() => MessageManager.success('Affiliate Created'), 500);
          break;
        }
    }

    // success
    if (!result.isAxiosError) {
      yield put({ type: actions.LOAD_AFFILIATES });
      return;
    }

    // error
    const { message } = result;
    console.log('Error: saveAffiliate:-->', message);
    yield put(actions.loadAffiliatesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadAffiliatesError(error));
  }
}

function* loginAsAffiliate({ payload }) {
  try {
    const { userId } = payload;

    const result = yield call(servicesApi.loginAsAffiliate, userId);

    // success
    if (!result.isAxiosError) {
      // TODO
      const { token } = result;

      // save old ID token in saved-admin
      const oldAdminToken = localStorage.getItem('id_token');
      localStorage.setItem('previous_admin_token', oldAdminToken);

      // save new ID token
      localStorage.setItem('id_token', token);

      // reload page
      history.replace('/dashboard');
      return;
    }

    // error
    const { message } = result;
    console.log('Error: loginAsAffiliate:-->', message);
    yield put(actions.loadAffiliatesError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadAffiliatesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_AFFILIATES, listAffiliates),
    takeEvery(actions.SAVE_AFFILIATE, saveAffiliate),
    takeEvery(actions.LOGIN_AS_AFFILIATE, loginAsAffiliate),
  ]);
}

// const adminKey = localStorage.getItem('id_token');
// const profile = decodeJwt(token).user;
// localStorage.setItem("id_token", code);
// history.replace("/dashboard");
// yield put(push('/dashboard'));
// localStorage.removeItem("id_token");
// window.location.reload();
