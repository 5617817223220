import _size from 'lodash/size';
import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  expenses: [],
  expensesSummary: [],
  tableStateFilters: {
    searchQuery: {},
    pagination: {
      current: 1,
      pageSize: 15,
    },
    sorter: {},
  },
  selected: null,
  expensesCSV: [],
  alertVisible: false,
};

export const getCurrentListSize = (state) => state && state.Expenses && _size(state.Expenses.expenses);

export default function reducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.LOAD_EXPENSES:
      return {
        ...state,
        isLoading: true,
        tableStateFilters: action.tableStateFilters,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_EXPENSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expenses: payload.data,
        errorMessage: false,
      };

    case actions.LOAD_EXPENSES_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expenses: [...state.expenses, ...payload.data],
        errorMessage: false,
      };

    case actions.LOAD_EXPENSES_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expensesSummary: payload.data,
        errorMessage: false,
      };

    case actions.LOAD_FROM_CAMPAIGN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selected: payload.data || null,
      };

    case actions.UPDATE_SELECTED:
      return {
        ...state,
        selected: payload.data,
      };
    case actions.LOAD_EXPENSES_CSV_SUCCESS:
      return {
        ...state,
        expensesCSV: payload,
        alertVisible: payload.length === 0 ? true : false,
      };
    case actions.CLEAN_LOAD_EXPENSES_CSV_SUCCESS:
      return {
        ...state,
        expensesCSV: [],
      };
    case actions.RESET_ALERT:
      return {
        ...state,
        alertVisible: false,
      };
    default:
      return state;
  }
}
