const MODULE = 'AUDIT';

const actions = {
    LIST_AUDIT:`LIST_AUDIT_${MODULE}`,
    LOAD_AUDIT_SUCCESS: `LOAD_AUDIT_SUCCESS_${MODULE}`,
    LOAD_AUDIT:`LOAD_AUDIT_${MODULE}`,
    LOAD_AUDIT_NEXT_PAGE_SUCCESS: `LOAD_AUDIT_NEXT_PAGE_SUCCESS_${MODULE}`,
    LOAD_AUDIT_ERROR: `LOAD_AUDIT_ERROR_${MODULE}`,

    // load
    loadAudit: ({pagination={}, searchQuery={}, sorter={}}) => {
        return {
            type: actions.LOAD_AUDIT,
            pagination,
            searchQuery,
            sorter
        }
    },
    loadAuditSuccess: data => ({
        type: actions.LOAD_AUDIT_SUCCESS,
        payload: { data },
    }),
    loadAuditNextPage: tablePagination => ({
        type: actions.LOAD_AUDIT,
        tablePagination
    }),
    loadAuditNextPageSuccess: data => ({
        type: actions.LOAD_AUDIT_NEXT_PAGE_SUCCESS,
        payload: { data },
    }),
    loadAuditError: error => ({
        type: actions.LOAD_AUDIT_ERROR,
        payload: { error },
    }),

};
export default actions;
