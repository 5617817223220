import actions from "./actions";

const initState = {
    isLoading: false,
    errorMessage: false,
    selected: null,
    leadsReport: []
};

export default function reducer(state = initState, action) {
    const {type, payload} = action;
    switch (type) {

        case actions.LOAD_LEADS_REPORT:
            return {
                ...state,
                isLoading: true,
            };

        case actions.LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload.error,
            };

        case actions.LOAD_LEADS_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                leadsReport: payload.data,
            };

        default:
            return state;
    }
}

