/* eslint-disable no-mixed-operators */
import { all, takeEvery, call, put, fork, take, cancel, delay, cancelled } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import servicesApi from './services';
import actions from './actions';
import _some from 'lodash/some';

const defaultCallback = () => {
  return false;
};

/**
 *
 * @param tablePagination
 * @param searchQuery
 * @param sorter
 * @returns {Generator<*, void, *>}
 */
function* listExpensesOperations({ tablePagination, searchQuery, sorter }) {
  try {
    let pageSettings = {
      skip: (tablePagination && tablePagination.current * tablePagination.pageSize) || 0,
    };

    // add filters parameters
    if (searchQuery) {
      pageSettings = {
        ...pageSettings,
        where: JSON.stringify(searchQuery),
      };
    }

    const [result, jobs] = yield all([call(servicesApi.list, pageSettings), call(servicesApi.jobsList, pageSettings)]);

    // success
    if (!result.isAxiosError) {
      yield put(actions.loadExpensesOperationsSuccess(result));
      yield put(actions.loadExpensesOperationsJobsSuccess(jobs));
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadExpensesOperationsError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error("You don't have permission to manage this service");
    }
  } catch (error) {
    yield put(actions.loadExpensesOperationsError(error));
  }
}

function* runExpenseOperation({ payload }) {
  const { data = {}, callBack = () => {} } = payload;

  let result = null;
  try {
    result = yield call(servicesApi.run, data.id, data);

    yield fork(watchers);
    yield put(actions.startJobsSync());
    // success
    if (result && !result.isAxiosError && callBack) {
      return callBack();
    }

    // error
    const { message } = result;
    yield put(actions.loadExpensesOperationsError({ error: message }));
  } catch (error) {
    yield put(actions.loadExpensesOperationsError(error));
  }
}

/**
 *
 * @param payload
 * @returns {Generator<*, *, null>}
 */
function* saveExpenseOperation({ payload }) {
  const { data, actionName, callBack = defaultCallback } = payload;

  let result = null;
  try {
    switch (actionName) {
      case 'insert':
        const { name = '', status = 'new', source = '' } = data;
        const existingRecord = yield call(servicesApi.list, { name, status, source });
        if (existingRecord && existingRecord.length > 0) {
          setTimeout(() => MessageManager.error('Mapping Item with defined attributes already exists'), 0);
          throw new Error('Mapping Item already exists');
        }

        result = yield call(servicesApi.create, data);
        setTimeout(() => MessageManager.success('ExpenseMapping Updated'), 500);
        break;

      case 'update':
        result = yield call(servicesApi.update, data.id, data);
        setTimeout(() => MessageManager.success('ExpenseMapping Updated'), 500);
        break;

      case 'delete':
        result = yield call(servicesApi.delete, data.id);
        setTimeout(() => MessageManager.success('ExpenseMapping Deleted'), 500);
        break;

      default:
        console.log('default action');
        break;
    }

    // success
    if (result && !result.isAxiosError && callBack) {
      return callBack();
    }

    // error
    const { message } = result;
    yield put(actions.loadExpensesOperationsError({ error: message }));

    if (result && result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadExpensesOperationsError(error));
  }
}

function* jobsSync() {
  try {
    const isRunning = yield cancelled();
    while (!isRunning) {
      const jobs = yield call(servicesApi.jobsList, {});
      const cronHasNewItems = _some(jobs, { status: 'new' });

      yield put(actions.loadExpensesOperationsJobsSuccess(jobs));
      yield delay(30 * 1000);

      if (!cronHasNewItems) {
        yield take(actions.STOP_BACKGROUND_SYNC);
      }
    }
  } catch (er) {
    yield cancelled();
    console.error('WATCHER Error', er);
  } finally {
    if (yield cancelled()) {
      console.error('watcher has been stopped');
    }
  }
}

function* watchers() {
  const watcherAction = yield take(actions.START_BACKGROUND_SYNC);

  while (watcherAction) {
    const bgSyncTask = yield fork(jobsSync);
    yield take(actions.STOP_BACKGROUND_SYNC);
    yield cancel(bgSyncTask);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_EXPENSE_OPERATION, saveExpenseOperation),
    takeEvery(actions.RUN_EXPENSE_OPERATION, runExpenseOperation),
    takeEvery(actions.LOAD_EXPENSES_OPERATIONS, listExpensesOperations),
  ]);
}
