import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from "antd";

import servicesApi from "./services";
import actions from './actions';

function downloadURI(url) {
    window.open(url, 'Download');
}

function* listAssets(action) {
    try {

        let id;
        let result;
        if(action && action.payload){
            const {affId} = action.payload;
            id=affId;
        }

        if (id){
            result = yield call(servicesApi.getAffAssets, id);
            result = result.data;
        } else {
            result = yield call(servicesApi.list);
        }


        // success
        if (!result.isAxiosError) {
            yield put(actions.loadAssetsSuccess(result));
            return;
        }

        // error
        const {message} = result;
        console.log("Error: listAssets:-->", message);
        yield put(actions.loadAssetsError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadAssetsError(error));
    }
}

function* saveAsset({payload}) {
    const {data, actionName} = payload;

    let result = null;
    try {

        switch (actionName) {
            case 'download':

                result = yield call(servicesApi.download, data.GSFileName);
                const url = result.url;
                downloadURI(url, 'image.png');

                setTimeout(() => MessageManager.success('Asset Updated'), 500);
                break;

            case 'update':
                result = yield call(servicesApi.update, data.id, data);
                setTimeout(() => MessageManager.success('Asset Updated'), 500);
                break;

            case 'delete':
                result = yield call(servicesApi.delete, data.id);
                setTimeout(() => MessageManager.success('Asset Deleted'), 500);
                break;

            default: // insert

                const files = data.fileList;
                if (!files) {
                    setTimeout(() => MessageManager.error('Error, no file selected'), 500);
                    return;
                }

                const assets = [];
                const fileObjInfo = yield call(servicesApi.uploadFile, files);

                const filesArray = fileObjInfo.files;

                delete data.fileList;

                filesArray.forEach(f => {

                    const newData = {...data};
                    const tmpUrl = f.url.split('/');

                    newData.Type = f.type;
                    newData.FileUrl = f.url;
                    newData.Size = f.size;
                    newData.GSFileName = tmpUrl[tmpUrl.length - 1];

                    assets.push(newData);
                });

                for (let i = 0; i < assets.length; i++) {
                    let asset = assets[i];
                    result = yield call(servicesApi.create, asset);
                }
                setTimeout(() => MessageManager.success('Asset Created'), 500);
                break;
        }

        // success
        if (!result.isAxiosError) {
            yield put({type: actions.LOAD_ASSETS});
            return;
        }

        // error
        const {message} = result;
        console.log("Error: Asset:-->", message);
        yield put(actions.loadAssetsError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadAssetsError(error));
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_ASSETS, listAssets),
        takeEvery(actions.SAVE_ASSET, saveAsset),
    ]);
}
