import service from "dcom-app/dist/services";

const servicesApi = {
  list: params =>
    service()
      .get('/expensesOperation', { params })
      .then(response => response.data)
      .catch(error => error),
  create: payload =>
    service()
      .post('/expensesOperation', payload)
      .then(response => response.data)
      .catch(error => error),
  update: (id, payload) =>
    service()
      .patch(`/expensesOperation/${id}`, payload)
      .then(response => response.data)
      .catch(error => error),
  delete: id =>
    service()
      .delete(`/expensesOperation/${id}`)
      .then(response => response.data)
      .catch(error => error),
  run: (id, payload) => {
    return service()
      .post('/expensesOperation/run', payload)
      .then(response => response.data)
      .catch(error => error);
  },
  jobsList: params =>
    service()
      .get('/expensesOperation/jobs', { params })
      .then(response => response.data)
      .catch(error => error),
}

export default servicesApi;
