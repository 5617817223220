import actions from "./actions";

const initState = {
    isLoading: false,
    errorMessage: false,
    selected: null,
    report: [],
    isLoadingCampaign: false,
    campaigns: [],
};

export default function reducer(state = initState, action) {
    const {type, payload} = action;
    switch (type) {

        case actions.LOAD_REPORT:
            return {
                ...state,
                isLoading: true,
            };

        case actions.LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload.error,
                report: [],
                isLoadingCampaign: false,
                campaigns: []
            };

        case actions.LOAD_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: payload.data,
            };

        case actions.LOAD_CAMPAIGNS:
            return {
                ...state,
                isLoadingCampaign: true,
                campaigns: [],
            };

        case actions.LOAD_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                isLoadingCampaign: false,
                campaigns: payload.data,
            };

        default:
            return state;
    }
}

