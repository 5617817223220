import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import { createApp } from 'dcom-app/dist/containers/main/MainApp';
import { init } from 'dcom-app/dist/sentry';
import bgImage from './assets/images/bk-2.jpg';
import { getAppRouters } from './routers';
import * as serviceWorker from './serviceWorker';
import reducer from './redux/reducers';
import sagas from './sagas';
import { BASE_URL, ENV } from './config';
// import { getSideMenuOptions } from 'dcom-app/dist/containers/Sidebar/helper';
import { getSideMenuOptions } from './sideMenuOptions'; //TODO reuse the Sidebar/helper

if (ENV === 'production') {
  const dsnKey = 'https://9be8af5f95d649f8824f1d9fb33b0a21@o412973.ingest.sentry.io/5511734';
  const releaseName = 'partner-portal-release';
  init(dsnKey, releaseName);
}

const config = {
  app: {
    theme: null,
    customStyle: null,
  },
  sideMenuOptions: getSideMenuOptions,
  modules: getAppRouters,
  services: {
    apiUrl: BASE_URL,
  },
  bgImage,
  getReducers: () => reducer,
  getSagas: () => sagas,
};

const MainApp = createApp(config);

ReactDOM.render(MainApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
