import service from 'dcom-app/dist/services';

const servicesApi = {
  readAdmin: () =>
    service()
      .get('/widget')
      .then(response => response.data)
      .catch(error => error),
  readAff: id =>
    service()
      .get(`/widget/?affiliate=${id}`)
      .then(response => response.data)
      .catch(error => error),
  create: payload =>
    service()
      .post('/widget', payload)
      .then(response => response.data)
      .catch(error => error),
  update: (id, payload) =>
    service()
      .patch(`/widget/${id}`, payload)
      .then(response => response.data)
      .catch(error => error),
  delete: id =>
    service()
      .delete(`/widget/${id}`)
      .then(response => response.data)
      .catch(error => console.log(error)),
  uglify: payload =>
    service()
      .post('/widget/uglify', payload)
      .then(response => response.data)
      .catch(error => error)
};

export default servicesApi;
