import Affiliate from '../containers/affiliates/reducers';
import Expenses from '../containers/expenses/reducers';
import ExpensesMapping from '../containers/expenses-mapping/reducers';
import ExpensesOperations from '../containers/expenses-ops/reducers';
import Widgets from '../containers/widget-builder/reducers';
import Assets from '../containers/assets/reducers';
import Report from '../containers/reportingCamp/reducers';
import Dashboard from '../containers/dashboard/reducers';
import ReportByAff from '../containers/reportingAff/reducers';
import ReportConversions from '../containers/reportingConv/reducers';
import Audit from '../containers/audit/reducers';
import Role from '../containers/role/reducers';
import User from '../containers/user/reducers';
import { reducer as Permissions } from '../containers/permission/controller';
import { reducer as PermissionsUI } from '../containers/permissionUI/controller';

export default {
  Affiliate,
  Expenses,
  ExpensesMapping,
  ExpensesOperations,
  Widgets,
  Assets,
  Report,
  Dashboard,
  ReportByAff,
  ReportConversions,
  Audit,
  Permissions,
  PermissionsUI,
  Role,
  User
};
