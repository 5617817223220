import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from "antd";
import servicesApi from "./services";
import actions from './actions';

function* loadReport(action) {
    try {

        const {startDate, endDate, affiliateId, campaignId} = action.payload;
        const result = yield call(servicesApi.loadReport, startDate, endDate, affiliateId, campaignId);

        // success
        if (!result.isAxiosError && result.response.event_conversion_report_response.success) {
            let reports = [];
            if(result.response.event_conversion_report_response.row_count > 0) {
                 reports = result.response.event_conversion_report_response.event_conversions.event_conversion;
            }

            yield put(actions.loadReportSuccess(reports));
            return;
        }

        // error
        const {message} = result;
        console.log("Error: ConversionReport:-->", message);
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadError(error));
    }
}

function* loadCampaigns(action) {
    try {
        const {affiliateId} = action.payload;

        const result = yield call(servicesApi.loadCampaigns, affiliateId);

        // success
        if (!result.isAxiosError && result.response.campaign_export_response.success) {
            let reports = [];
            if(result.response.campaign_export_response.row_count > 0) {
                reports = result.response.campaign_export_response.campaigns.campaign;
            }

            yield put(actions.loadCampaignsSuccess(reports));
            return;
        }

        // error
        const {message} = result;
        console.log("Error: loadCampaigns:-->", message);
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_REPORT, loadReport),
        takeEvery(actions.LOAD_CAMPAIGNS, loadCampaigns),
    ]);
}
