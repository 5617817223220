import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import servicesApi from './services';
import actions from './actions';
import { codeToUser } from './data/showcaseConfig';

// Access to the Store
const getWidgetStore = (state) => state.Widgets;
const getAdmin = (state) => state.Auth.profile.roles[0].id;

const getAffiliate = (state) => {
  return state.Auth.profile.affiliate.length !== 0 ? state.Auth.profile.affiliate[0].id : '';
};

function* loadWidgetsSaga() {
  try {
    const affiliateId = yield select(getAffiliate);
    const isAdmin = yield select(getAdmin);
    const result = isAdmin === 1 ? yield call(servicesApi.readAdmin) : yield call(servicesApi.readAff, affiliateId);
    // success
    if (!result.isAxiosError) {
      yield put(actions.loadListWidgetsSuccess(result));
      return;
    }
    // error
    const { message } = result;
    console.log('Error: listAffiliates:-->', message);
    yield put(actions.loadListWidgetsError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadListWidgetsError(error));
  }
}

function* deleteWidgetSaga({ payload }) {
  try {
    const result = yield call(servicesApi.delete, payload);
    // success
    if (!result.isAxiosError) {
      yield put(actions.deleteWidgetFromListSuccess(result));
      MessageManager.success('Widget successfully removed', 3);
      return;
    }
    // error
    const { message } = result;
    console.log('Error: listAffiliates:-->', message);
    yield put(actions.deleteWidgetFromListError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again', 3);
    }
  } catch (error) {
    MessageManager.success("The widget couldn't be removed", 3);
    yield put(actions.deleteWidgetFromListError({ error }));
  }
}

function* uglifyCodeSaga({ payload }) {
  try {
    const result = yield call(servicesApi.uglify, { code: payload.code });
    // success
    if (!result.isAxiosError) {
      yield put(actions.uglifyCodeSuccess(result.code));
      MessageManager.success('Code have been minified', 3);
      const widgetStore = yield select(getWidgetStore);
      const affiliateId = yield select(getAffiliate);
      const isAdmin = yield select(getAdmin);
      const { widgetConfiguration, currentWidgetName, currentWidgetId, currentAffId } = widgetStore;
      const affiliate = isAdmin === 1 ? currentAffId : affiliateId;
      const widgetCode = yield call(codeToUser, widgetConfiguration, result.code);
      yield put(actions.createWidgetCode(widgetCode));
      const newPayload = {
        name: currentWidgetName,
        type: widgetConfiguration.widget,
        widgetCode,
        affiliate: parseInt(affiliate, 10),
      };
      yield put(
        payload.nextAction === 'ADD'
          ? actions.addWidget(newPayload)
          : actions.updateWidget({ id: currentWidgetId, payload: newPayload })
      );
      return;
    }

    // error
    const { message } = result;
    console.log('Error: Uglify:-->', message);
    yield put(actions.uglifyCodeError({ error: message }));
    MessageManager.error('Error please try again', 2);
    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    MessageManager.error('Error please try again', 2);
    yield put(actions.uglifyCodeError({ error }));
  }
}

function* addWidgetSaga({ payload }) {
  try {
    const result = yield call(servicesApi.create, payload);
    if (!result.isAxiosError) {
      yield put(actions.addWidgetSuccess(result));
      MessageManager.success('New widget added', 3);
      return;
    }
    // error
    const { message } = result;
    console.log('Error: listAffiliates:-->', message);
    yield put(actions.addWidgetError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.addWidgetError({ error }));
    MessageManager.error('Error happened when trying to add widget', 3);
  }
}

// update widget
function* updateWidgetSaga({ payload }) {
  try {
    const result = yield call(servicesApi.update, payload.id, payload.payload);
    if (!result.isAxiosError) {
      yield put(actions.updateWidgetSuccess(result));
      MessageManager.success('Widget successfully updated ', 3);
      return;
    }
    // error
    const { message } = result;
    console.log('Error: listAffiliates:-->', message);
    yield put(actions.addWidgetError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.addWidgetError({ error }));
    MessageManager.error('Error happened when trying to update widget', 3);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UGLIFY_CODE, uglifyCodeSaga)]);
  yield all([takeEvery(actions.ADD_WIDGET, addWidgetSaga)]);
  yield all([takeEvery(actions.UPDATE_WIDGET, updateWidgetSaga)]);
  yield all([takeEvery(actions.LOAD_LIST_WIDGETS, loadWidgetsSaga)]);
  yield all([takeEvery(actions.DELETE_WIDGET_FROM_LIST, deleteWidgetSaga)]);
}

// const adminKey = localStorage.getItem('id_token');
// const profile = decodeJwt(token).user;
// localStorage.setItem("id_token", code);
// history.replace("/dashboard");
// yield put(push('/dashboard'));
// localStorage.removeItem("id_token");
// window.location.reload();
