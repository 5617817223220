const MODULE = 'REPORTS-CONV';

const actions = {
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
    LOAD_REPORT: `LOAD_REPORT_${MODULE}`,
    LOAD_REPORT_SUCCESS: `LOAD_LEADS_REPORT_SUCCESS_${MODULE}`,
    LOAD_CAMPAIGNS: `LOAD_CAMPAIGNS_${MODULE}`,
    LOAD_CAMPAIGNS_SUCCESS: `LOAD_CAMPAIGNS_SUCCESS_${MODULE}`,

    // load
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: { error },
    }),

    loadReport: data => ({
        type: actions.LOAD_REPORT,
        payload: { ...data },
    }),
    loadReportSuccess: data => ({
        type: actions.LOAD_REPORT_SUCCESS,
        payload: { data },
    }),

    loadCampaigns: data => ({
        type: actions.LOAD_CAMPAIGNS,
        payload: { ...data },
    }),
    loadCampaignsSuccess: data => ({
        type: actions.LOAD_CAMPAIGNS_SUCCESS,
        payload: { data },
    })
};
export default actions;
