
/**
 * Generate left menu by user permission
 * @param profile
 */
export const getSideMenuOptions = permissions => {
    if (!permissions) return [];

    return mapPermissions(permissions);
};

// UTILS
function mapPermissions(permissions = []) {

    let result;

    // 1- SORT BY `ORDER`
    const sortedList = permissions.sort((a, b) => a.order - b.order);

    // 2- FIND THE `ROOT` S
    const roots = sortedList.filter((x)=> x.parent === 0);

    // 3- FIND THE CHILDREN HIERARCHY BY EACH `ROOT`
    result =  getTree(roots, permissions); //TODO return the right menu

    return result;
}

function getTree(roots = [], permissions = []) {
    const result = [];

    roots.forEach( x => {

        const { id, label, parent, route, icon } = x;

        let path = route;
        let isRootChild = false;

        const children = permissions.filter(x => x.parent === id);

        if (children.length > 0 && children[0].action) {
            isRootChild = true;
        }

        if (isRootChild){
            path = children[0].route;
        }

        if (isRootChild) { // rootChild
            const menuItem = {
                "key": path,
                "label": parent ? label : label,
                "leftIcon": icon,
            }
            result.push(menuItem);
        } else {           // root

            const menuItem = {
                "key": label,
                "label": parent ? label : label,
                "leftIcon": icon,
                "children": children.map(y => {

                    let path2 = y.route;

                    const children = permissions.filter(x => x.parent === y.id);

                    if (children.length > 0 ) {
                        path2 = children[0].route;
                    }

                    return {
                        "key": path2,
                        "label": y.label,
                    };

                }),
            }
            result.push(menuItem);
        }
    });
    return result;
}

// const sideMenuOptions = [
//     {
//         key: '',
//         label: 'Dashboard',
//         leftIcon: 'ion-ios-speedometer',
//         role: [1, 2]
//     },
//     {
//         key: 'widget-builder',
//         label: 'Widget Builder',
//         leftIcon: 'ion-wrench',
//         role: [1, 2]
//     },
//     {
//         key: 'assets',
//         label: 'Assets',
//         leftIcon: 'ion-cube',
//         role: [1, 2]
//     },
//     {
//         key: 'reporting-parent',
//         label: 'Reporting',
//         leftIcon: 'ion-ios-pie',
//         role: [1, 2],
//         children: [
//             {
//                 key: 'campaign-summary',
//                 label: 'Campaign Summary',
//                 role: [1, 2],
//             },
//             {
//                 key: 'conversions',
//                 label: 'Conversions',
//                 role: [1, 2],
//             }
//         ]
//     },
//     {
//         key: 'buyers',
//         label: 'Buyers',
//         leftIcon: 'ion-bag',
//         role: [1, 2],
//         children: [
//             {
//                 key: 'retreaver-buyers',
//                 label: 'Retreaver Buyers',
//                 role: [1, 2],
//             }
//         ]
//     },
//     {
//         key: 'affiliates',
//         label: 'Affiliates',
//         leftIcon: 'ion-person-stalker',
//         role: [1]
//     },
//     {
//         key: 'expenses',
//         label: 'Expenses',
//         leftIcon: 'ion-cash',
//         role: [1,3],
//         children: [
//             {
//                 key: 'expenses-mapping',
//                 label: 'Mapping',
//                 role: [1, 3],
//             },
//             {
//                 key: 'expenses',
//                 label: 'Expenses',
//                 role: [1, 3],
//             }
//         ]
//     },
//     {
//         key: "configuration",
//         label: "System",
//         leftIcon: "ion-gear-b",
//         role: [1]
//     },
//     {
//         key: "audit",
//         label: "Audit",
//         leftIcon: "ion-gear-b",
//         role: [1]
//     },
//     {
//         key: 'role',
//         label: 'Roles',
//         leftIcon: 'ion-ios-barcode-outline',
//         role: [1],
//     },
//     {
//         key: 'permission',
//         label: 'Permission',
//         leftIcon: 'ion-ios-barcode-outline',
//         role: [1],
//     },
//     {
//         key: 'permission-ui',
//         label: 'Permission UI',
//         leftIcon: 'ion-ios-barcode-outline',
//         role: [1],
//     },
// ];
// export default sideMenuOptions;
