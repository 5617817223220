import actions from './actions';
import _size from 'lodash/size';

const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    audit: [],
    tableStateFilters: {
        searchQuery: {},
        pagination: {
            current: 1,
            pageSize: 15
        },
        sorter: {},
    },
    selected: null
};

export const getCurrentListSize = state => state && state.Audit && _size(state.Audit.audit);

export default function reducer(state = initState, action) {
    const {type, payload} = action;
    switch (type) {

        case actions.LOAD_AUDIT:
            return {
                ...state,
                isLoading: true,
                tableStateFilters: action.tableStateFilters,
                errorMessage: false,
                modalActive: false,
            };

        case actions.LOAD_AUDIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                audit: payload.data,
                errorMessage: false,
            };

        case actions.LOAD_AUDIT_NEXT_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                audit: [...state.audit, ...payload.data],
                errorMessage: false,
            };

        case actions.LOAD_FROM_CAMPAIGN_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'There is a loading problem',
            };

        case actions.TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                selected: payload.data || null,
            };

        case actions.UPDATE_SELECTED:
            return {
               ...state,
               selected: payload.data
            };

        default:
            return state;
    }
}

