import service from "dcom-app/dist/services";

const servicesApi = {
    list: params => {
        return service()
            .get('/audit', { params })
            .then(response => response.data)
            .catch(error => error)
    },
    delete: id =>
        service()
            .delete(`/audit/${id}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
