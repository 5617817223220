import actions from './actions';

const initState = {
  widgets: [],
  isLoading: false,
  errorMessage: '',
  currentWidgetId: '',
  currentAffId: null,
  currentWidgetName: '',
  widgetConfiguration: {},
  codeSnippet: '',
  uglifyCode: '',
  getCodeBtnDisabled: true,
  widgetUpdatesAvailable: false,
  disableConfigControls: true
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    // UGLIFY CODE
    case actions.UGLIFY_CODE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actions.UGLIFY_CODE_SUCCESS: {
      return {
        ...state,
        uglifyCode: payload
      };
    }
    case actions.UGLIFY_CODE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Uglify failed'
      };
    }

    // ADD WIDGET
    case actions.ADD_WIDGET:
      return {
        ...state
      };
    case actions.ADD_WIDGET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        currentWidgetId: payload.id,
        widgets: [...state.widgets, payload],
        errorMessage: '',
        getCodeBtnDisabled: false,
        widgetUpdatesAvailable: true
      };
    }
    case actions.ADD_WIDGET_ERROR:
      return {
        ...state,
        isLoading: false,
        getCodeBtnDisabled: true,
        errorMessage: 'There was a problem while loading the widgets data'
      };

    // UPDATE WIDGET
    case actions.UPDATE_WIDGET:
      return {
        ...state
      };
    case actions.UPDATE_WIDGET_SUCCESS: {
      const updatedWidgets = state.widgets.filter(widget => widget.id !== state.currentWidgetId);
      return {
        ...state,
        isLoading: false,
        currentWidgetId: payload.id,
        widgets: [...updatedWidgets, payload],
        errorMessage: '',
        getCodeBtnDisabled: false,
        widgetUpdatesAvailable: true
      };
    }

    case actions.UPDATE_WIDGET_ERROR:
      return {
        ...state,
        isLoading: false,
        getCodeBtnDisabled: true,
        errorMessage: 'There was a problem while loading the widgets data'
      };
    // LOAD WIDGETS
    case actions.LOAD_LIST_WIDGETS:
      return {
        ...state,
        isLoading: true
      };
    case actions.LOAD_LIST_WIDGETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        widgets: payload.data,
        errorMessage: ''
      };
    case actions.LOAD_LIST_WIDGETS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There was a problem while loading the widgets data'
      };

    // DELETE WIDGET
    case actions.DELETE_WIDGET_FROM_LIST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actions.DELETE_WIDGET_FROM_LIST_SUCCESS: {
      const { widgets } = state;
      const updatedWidgets = widgets.filter(widget => widget.id !== payload);
      return {
        ...state,
        isLoading: false,
        widgets: updatedWidgets
      };
    }
    case actions.DELETE_WIDGET_FROM_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error
      };
    }

    // LOAD WIDGETS AT THE SHOWCASE
    case actions.LOAD_SHOWCASE_WIDGET: {
      return {
        ...state,
        widgetConfiguration: payload
      };
    }

    case actions.CHANGE_CURRENT_WIDGET_NAME: {
      return {
        ...state,
        currentWidgetName: payload,
        getCodeBtnDisabled: true
      };
    }
    case actions.CHANGE_CURRENT_AFF_ID: {
      return {
        ...state,
        currentAffId: payload,
        getCodeBtnDisabled: true
      };
    }
    case actions.CHANGE_WIDGET_PROPERTIES: {
      const { property, newValue } = payload;
      return {
        ...state,
        getCodeBtnDisabled: true,
        widgetConfiguration: {
          ...state.widgetConfiguration,
          customize: {
            ...state.widgetConfiguration.customize,
            [property]: newValue
          }
        }
      };
    }
    case actions.CHANGE_WIDGET_VERTICALS: {
      const { property, newValue } = payload;
      return {
        ...state,
        getCodeBtnDisabled: true,
        widgetConfiguration: {
          ...state.widgetConfiguration,
          customize: {
            ...state.widgetConfiguration.customize,
            activeVerticals: {
              ...state.widgetConfiguration.customize.activeVerticals,
              [property]: newValue
            }
          }
        }
      };
    }
    case actions.CREATE_WIDGET_CODE: {
      return {
        ...state,
        codeSnippet: payload
      };
    }
    // RESET STORE
    case actions.RESET_STORE: {
      return {
        ...state,
        widgetConfiguration: {},
        codeSnippet: '',
        currentWidgetName: '',
        uglifyCode: '',
        getCodeBtnDisabled: true,
        widgetUpdatesAvailable: false,
        currentWidgetId: ''
      };
    }
    case actions.DISABLE_CONFIG_PANEL_CONTROLS:{
      return {
        ...state,
        disableConfigControls: payload
      };
    }
    default:
      return state;
  }
}
